import { ActionTree } from 'vuex';
import { bannersAdapter } from '@api';
import { BannersPageState } from '.';
import { getLastModified, setLastModified } from '@/shared/utils';

const actions: ActionTree<BannersPageState, RootState> = {
    setPageIndex({ commit }, index: number): void {
        commit('setPageIndex', index); //NOTE: accessing the module action
    },
    async loadFilters({ commit }) {
        commit('setFilters', await bannersAdapter.getBannerFilters());
    },
    async delete({ commit }, ids: number[]): Promise<void> {
        try {
            await bannersAdapter.delete(ids);
            const modified = getLastModified();
            if (modified.length) {
                setLastModified(modified.filter(id => !ids.includes(id)));
            }
            commit('updateSelected', []);
        } catch (error) {
            throw error;
        }
    },
};
export default actions;
