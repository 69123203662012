import { GetterTree } from 'vuex';
import { BannersPageState } from '.';

const getters: GetterTree<BannersPageState, RootState> = {
    filters(state) {
        return state.filters;
    },
};

export default getters;
