







































import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import { BannerListFilters, BannerListFilterParams } from '../models';

@Component({})
export default class BannerFilters extends Vue {
    @Prop({ default: { tags: [], types: [], dimensions: [] }, type: Object }) filters!: BannerListFilters;
    @Model('change', { default: () => ({ tags: [], types: [], dimensions: [] }), type: Object }) value!: Pick<
        BannerListFilterParams,
        'dimensions' | 'types' | 'tags'
    >;
    get dimensions() {
        return this.value.dimensions;
    }
    set dimensions(dimensions) {
        this.$emit('change', { ...this.value, dimensions });
    }
    get types() {
        return this.value.types;
    }
    set types(types) {
        this.$emit('change', { ...this.value, types });
    }
}
