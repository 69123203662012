

















































import store from './store';
import { namespace, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { bannersAdapter } from '@api';
import { debounce } from '@shared/utils/debounce';
import { PAGINATION_MUTATIONS, PAGINATION_ACTIONS, PadinationLoadOptions } from '../../store/modules/paginator';
import { BannerListFilters, BannerListFilterParams, BannerPreview } from './models';
import BannerCard from './components/BannerCard.vue';
import BannerFilters from './components/BannerFilters.vue';
import DialogModal from '@/shared/components/Modal/DialogModal.vue';
import { saveImage, downloadFromLink, getLastModified, getLastModifiedFamily } from '../../shared/utils';
import { FamiliesType } from '@shared/models/FamiliesType';
const ns = namespace('banners');
const pg = namespace('banners/pg');

@Component({
    components: {
        BannerCard,
        BannerFilters,
    },
    created() {
        this.$registerVuexModule('banners', store);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name && from.name.includes('editor') && vm.$store.state.editor?.wasSaved) {
                vm.$store.commit(`banners/pg/${PAGINATION_MUTATIONS.setParams}`, { ids: getLastModified() });
            }
        });
    },
})
export default class BannersPage extends Vue {
    @State('families') families!: FamiliesType[];
    @ns.State('selected') selected!: number[];
    @ns.Getter('filters') filters!: BannerListFilters;
    @ns.Mutation('updateSelected') updateSelected!: (selected: number[]) => void;
    @ns.Action('loadFilters') loadFilters!: () => Promise<void>;
    @ns.Action('delete') delete!: (payload: number[]) => Promise<void>;
    @pg.Getter('pageItems') items!: BannerPreview[];
    @pg.Getter('total') totalItems!: number;
    @pg.Getter('dataId') dataId!: number;
    @pg.State('params') params!: BannerListFilterParams;
    @pg.Action(PAGINATION_ACTIONS.load) getPage!: (options: PadinationLoadOptions) => Promise<boolean>;
    @pg.Mutation(PAGINATION_MUTATIONS.setParams) setParams!: (params: Partial<BannerListFilterParams>) => void;
    @pg.Mutation(PAGINATION_MUTATIONS.resetData) resetData!: () => void;

    initialPageSize?: number;
    filtersLoaded = false;

    async mounted() {
        await this.loadFilters();
        this.$nextTick(() => {
            if (this.lastModified && this.lastmodifiedTag && this.families.find(f => f.id === this.lastmodifiedTag)) {
                this.setParams({ familyIds: [this.lastmodifiedTag], ids: [] });
            } else if (this.filters.tags) {
                this.setParams({ familyIds: this.families.slice(0, 1).map(f => f.id), ids: [] });
            }
            this.filtersLoaded = true;
        });
    }

    async loadPage({ scrollEvent, reload = false }: PadinationLoadOptions) {
        const data = await this.getPage({ reload: reload, scrollEvent });
    }

    loadPageDebounced = debounce(this.loadPage, 500);

    filterChanged(changes: Partial<BannerListFilterParams>) {
        this.setParams(changes);
        this.loadPageDebounced({ reload: true });
        this.updateSelected([]);
    }

    get filtersParams() {
        const { query, sort, ...filtersParams } = this.params;
        return filtersParams;
    }

    get buttonText() {
        return this.selected.length ? 'EDIT BANNERS' : 'SELECT BANNERS TO EDIT';
    }

    get activeFamily() {
        return this.families.find(f => f.id === this.params.familyIds?.[0]);
    }

    goToEditor() {
        this.$router.push(`/editor/edit?${this.selected.map(s => 'ids=' + s).join('&')}`);
    }

    deleteImages() {
        this.$modal.show(
            DialogModal,
            {
                title: 'Delete',
                question: this.selected.length == 1 ? 'Delete the selected banner' : `Delete ${this.selected.length} selected banners?`,
                action: async () => {
                    const dimesions = this.items.filter(i => this.selected.includes(i.id)).map(i => `${i.width} x ${i.height}`);
                    await this.delete([...this.selected]);
                    this.filterChanged({ dimensions: this.params.dimensions.filter(d => !dimesions.includes(d)) });
                    this.loadFilters();
                    return;
                },
            },
            {},
            {}
        );
    }

    get lastModified() {
        return getLastModified();
    }

    get lastmodifiedTag() {
        return getLastModifiedFamily();
    }

    download() {
        this.items.forEach(i => {
            if (this.selected.includes(i.id)) {
                try {
                    downloadFromLink(this.$options?.filters?.assetsUrl(i.defaultUrl), i.name);
                    bannersAdapter.logBannerDownload(i.id);
                } catch (error) {
                    console.warn('Fail to download image with path: ' + i.defaultUrl);
                }
            }
        });
    }

    destroyed() {
        this.resetData();
    }

    toggleLast() {
        this.filterChanged({ ids: this.params.ids?.length ? [] : this.lastModified });
    }
}
