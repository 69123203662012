import { MutationTree } from 'vuex';
import { BannerListFilters } from '../models';
import { BannersPageState } from '.';

const mutations: MutationTree<BannersPageState> = {
    setFilters(state, payload: BannerListFilters) {
        state.filters = payload;
    },
    updateSelected(state, payload: number[]) {
        state.selected = payload;
    },
};
export default mutations;
